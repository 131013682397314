@import '../variables';

div {
  .actionItem {
    display: inline-flex;
    /* border: 1px solid black; */
    padding: 1em;
    margin-right: 4em;
    margin-bottom: 2em;
    margin-top: 2em;
    width: $elementWidth;
    background: $elementBgColor;
    max-height: $elementMaxHeight;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
  }
}
.rowC {
  display: flex;
  flex-direction: row;
}
.columnC {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
