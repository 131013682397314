@import url('./../../config/ConfigColor.css');

.active {
  color: var(--active, green);
}

.action-due {
  color: var(--action-due, green);
}

.no-action-due {
  color: var(--no-action-due, yellow);
}

.waiting-for-response {
  color: var(--waiting-for-response, yellow);
}

.waiting-for-delay {
  color: var(--waiting-for-delay, orange);
}

.in-active {
  color: var(--in-active, grey);
}
